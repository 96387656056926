.final {
    background-color: #f5f7fa;
    padding-bottom: 50px;
}

.final-body {
    display: flex;
    justify-content: space-between;
    font-family: yandexSansTextRegular;
    font-size: 16px;
    color: #2b3d51;
    width: 100%;
    max-width: 950px;
    padding: 0 60px;
    margin-top: 20px;
}

.final-left {
    display: flex;
    align-items: center;
    flex: 0 0 330px;
    flex-direction: column;
    margin-right: 10%;
    order: 2
}

.final-left h2 {
    line-height: 1;
}

.final-left-text-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #78909c;
    margin-bottom: 10px;
    padding: 10px 20px;
    text-align: center;
}

.final-left-price {
    font-family: yandexSansTextBold;
    font-size: 36px;
    color: #2dca72;
}

.final-right {
    display: flex;
    flex: 0 1 480px;
    flex-direction: column;
    order: 3
}

@media (max-width: 750px) {
    .final-left {
        margin-right: 0;
    }
    .final-right {
        order: 1;
        flex: 100%;
        margin-bottom: 20px;
    }
    .final-body {
        flex-wrap: wrap;
        justify-content: center;
    }
}