.about2-body {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 1024px;
    justify-content: space-between;
    padding: 0 0 60px 0;
}

.about2-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 465px;
    padding-top: 25px;
}

.about2-left div {
    display: flex;
    align-items: flex-start;
}

.about2-left img {
    width: 100%
}

.about2-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1 0 445px;
    max-width: 445px;
    padding-top: 30px;
    margin-left: 30px;
}

.about2-right h2 {
    font-size: 20px;
}

.about2-right .ssmm-btn {
    max-width: 340px;
}

.about2-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px 0 30px 0;
}

.about2-list .list-item {
    flex: 0 0 220px;
}

.about2-right p {
    font-family: yandexSansTextMedium;
    font-size: 20px;
    color: #7e8a95;
    margin-bottom: 20px;
}

.about2-left .ant-spin-nested-loading,
.about2-left .ant-spin-container {
    width: 100%;
}

.about2 .list-item-more {
    color: #2b3d51;
    text-decoration: underline;
    cursor: pointer;
}

.about2 .list-item-more:hover {
    opacity: .7;
}

@media (max-width: 1120px) {
    .about2 .container {
        padding: 0 20px;
    }
}

@media (max-width: 996px) {
    .about2-body {
        justify-content: center;
    }
    .about2-right {
        max-width: 100%;
        flex: 1 0 100%;
        align-items: center;
        text-align: center;
        margin-left: 0;
    }
    .about2-list {
        justify-content: center;
    }
    .about2-right .ssmm-btn {
        align-self: center;
    }
}