.target-slide {
    margin: 3%;
    padding: 7%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    max-width: 450px;
    min-width: 250px;
}

.target-slide-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.target-slide-list {
    display: flex;
    flex-direction: column;
    flex: 1
}

.target-slide-list .list-item {
    margin-bottom: 10px;
}

.target-slide-case {
    margin-top: 15px;
    text-align: center;
    margin-bottom: 25px;
}

.target-slide-case button {
    font-family: yandexSansTextBold;
    font-size: 13px;
}

.target-slide-case button:hover {
    text-decoration: underline;
}

.target-slide-video img {
    width: 100%;
    cursor: pointer;
}

.target-slide-video {
    position: relative;
    height: 0;
    overflow: hidden;
    width: 100%;
    padding-bottom: 56.25%;
}

.target-slide-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}