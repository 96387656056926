.rates-term-wrap {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 5px;
    width: 100%;
}

.rates-term-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.rates-term-selectTerm {
    display: flex;
    flex: 0 0 220px;
    margin-right: 15px;
    width: 220px;
    font-family: yandexSansTextBold;
    font-size: 15px;
    color: #78909c;
    border: 2px solid #78909c;
    height: 50px;
    justify-content: center;
    align-items: center;
}

.rates-term-btn {
    font-family: yandexSansTextBold;
    font-size: 15px;
    color: #78909c;
    width: 100%;
    max-width: 150px;
    min-width: 120px;
    height: 50px;
    border-radius: 25px;
    border: 2px solid #78909c;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: 3px;
    margin-right: 3px;
    transition: .2s;
    animation: shine 3s cubic-bezier(.12, .89, .98, .47) infinite;
}

.rates-term-btn:not(.rates-term-btn-active):hover {
    box-shadow: 0px 0px 20px 0px rgba(10, 10, 10, .15);
    transition: .2s;
}

.rates-term-btn-active {
    color: #fff;
    border: none;
    background-color: #2dca72;
    transition: .2s;
    cursor: default;
}

@media (max-width: 870px) {
    .rates-term-container {
        justify-content: space-around;
        max-width: 620px;
        margin-left: 0;
        margin-bottom: 20px;
        flex-wrap: wrap;
    }
    .rates-term-selectTerm {
        display: none;
    }
    .rates-term-btn {
        width: unset;
        height: unset;
        padding: 10px 20px;
        margin-bottom: 10px;
    }
}

@keyframes shine {
    from {
        background-repeat: no-repeat;
        background-image: -webkit-linear-gradient( top left, rgba(45, 202, 114, 0.0) 0%, rgba(45, 202, 114, 0.0) 45%, rgba(45, 202, 114, 0.5) 48%, rgba(45, 202, 114, 0.8) 50%, rgba(45, 202, 114, 0.5) 52%, rgba(45, 202, 114, 0.0) 57%, rgba(45, 202, 114, 0.0) 100%);
        background-position: -250px -250px;
        background-size: 400px 400px
    }
    to {
        background-repeat: no-repeat;
        background-position: 250px 250px;
    }
}