header {
    position: absolute;
    width: 100%;
}

header .header-cont {
    align-items: center;
    justify-content: space-between;
    color: white;
    height: 106px;
}

.header-logo {
    width: 138px;
}

header .ssmm-btn {
    margin-left: 10px;
}