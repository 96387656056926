.advantages {
    margin-bottom: 50px;
}

.advantages-body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
}

.advantages-left {
    order: 1
}

.advantages-right {
    order: 3
}

.advantages-left,
.advantages-right {
    flex: 0 0 265px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.advantages-block {
    margin-bottom: 30px;
}

.advantages-block:last-child {
    margin-bottom: 0;
}

.advantages-block span {
    font-family: yandexSansTextRegular;
    font-size: 16px;
    color: #2b3d51;
}

.advantages-left .advantages-block {
    text-align: right;
}

.advantages-left .advantages-block h3 {
    text-align: right;
}

.advantages-right .advantages-block h3 {
    text-align: left;
}

.advantages-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 1 300px;
    margin: 0 4%;
    order: 2
}

.advantages-center .ssmm-btn {
    margin-bottom: 10px;
    max-width: 300px;
}

.advantages-center img {
    width: 100%;
}

@media (max-width: 1040px) {
    .advantages-center {
        text-align: center;
        flex: 0 1 50%;
        order: 4;
        margin-top: 50px;
    }
    .advantages-right {
        margin-left: 5%;
        padding-left: 5%;
        border-left: 1px solid #7e8a95;
    }
    .advantages-left,
    .advantages-right {
        flex: 0 0 40%;
    }
}

@media (max-width: 650px) {
    .advantages-right {
        margin-left: 0;
        padding-left: 0;
        border-left: none
    }
    .advantages-left,
    .advantages-right {
        flex: 0 0 100%;
    }
    .advantages-left {
        margin-bottom: 30px;
    }
    .advantages-left .advantages-block h3,
    .advantages-right .advantages-block h3 {
        text-align: center;
    }
    .advantages-left .advantages-block,
    .advantages-right .advantages-block {
        text-align: center;
    }
}