/* этот элемент перекрывает меню
вроде бы оно починено, может в ближайших версиях исправится */

header .ant-dropdown:before {
    display: none;
}

nav .dropdown-menu-btn {
    font-size: 50px;
    cursor: pointer;
    transition: .2s
}

nav .dropdown-menu-btn-active {
    color: #66bb6a;
}

.dropdown-menu ul {
    list-style: none;
    margin: 0;
}

.dropdown-menu ul li {
    display: flex;
    justify-content: center;
}

.dropdown-menu ul li:hover {
    background: #f5f7fa;
}

.dropdown-menu ul li button,
.dropdown-menu ul li a {
    display: block;
    padding: 10px 40px;
    font-family: yandexSansTextMedium;
    font-size: 30px;
    color: #2f3b64;
    text-align: center;
    width: 100%;
}

.dropdown-menu .ssmm-btn {
    font-size: 20px;
    margin: 10px auto;
    height: 60px;
    border-radius: 30px;
}

header .ant-dropdown {
    width: 350px;
}

.dropdown-menu {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 30px 100px 0px rgba(26, 26, 26, 0.5);
}

.dropdown-menu li {
    border-bottom: 1px solid #e7e7e7
}

@media (max-width: 500px) {
    header .ant-dropdown {
        width: 100%;
    }
}

@media (hover: hover) {
    nav .dropdown-menu-btn:hover {
        color: #66bb6a;
        transition: .2s
    }
}