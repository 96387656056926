.rates {
    padding-bottom: 60px;
}

.rates .subheader {
    margin-bottom: 5px;
}

.rates-warning {
    font-family: yandexSansTextBold;
    font-size: 15px;
    color: #f17775;
    width: 100%;
    text-align: center;
}

.rates-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 880px;
    max-width: 880px;
}

.rates-spin-cont {
    height: 675px;
    display: flex;
    align-items: center;
}

.rates-beta-warning {
    margin-top: 40px;
    font-family: yandexSansTextRegular;
    font-size: 13px;
    color: #2b3d51;
}

.rates-browsers-supported-warning {
    margin-bottom: 20px;
    font-family: yandexSansTextRegular;
    font-size: 15px;
    color: red;
}

@media (max-width: 370px) {
    .rates .container {
        padding: 0 10px;
    }
}