.about3-slider:nth-child(2) {
    margin-top: 15px;
}

.about3-slider p {
    margin-bottom: 10px;
}

.slick-prev-custom,
.slick-next-custom {
    position: absolute;
    top: calc(50% - 8px);
    width: 16px;
    height: 16px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    border-top: 3px solid #3383ff;
    border-right: 3px solid #3383ff;
    transition: .2s
}

.slick-prev-custom:hover,
.slick-next-custom:hover {
    border-color: #2f3b64;
    transition: .2s
}

.about3-slider-video .slick-prev-custom,
.about3-slider-video .slick-next-custom {
    top: calc(50% - 24px);
}

.slick-prev-custom {
    left: -25px;
    transform: rotate(-135deg)
}

.slick-next-custom {
    right: -25px;
    transform: rotate(45deg)
}

.about3-slider-slide {
    height: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 5px;
    cursor: pointer;
    transition: .2s
}

.about3-slider-slide:hover {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, .3);
    transition: .3s
}

.about3-slider-slide-title {
    text-align: center;
    line-height: 26px;
    color: #2b3d51;
    padding-top: 6px;
}

@media (max-width: 800px) {
    .about3-slider-slide {
        height: 200px;
    }
}