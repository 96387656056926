.rates-table {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
}

.rates-table-cols-wrap {
    position: relative;
    display: flex;
    flex: 1 0 160px;
}

.rates-table-header {
    display: flex;
    flex: 1 0 150px;
    flex-direction: column;
    min-width: 150px;
    max-width: 220px;
}

.rates-table-header-header {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f7f7f7;
    margin-bottom: 10px;
}

.rates-table-header-body {
    display: flex;
    flex-direction: column;
}

.rates-table-header-soc-info svg,
.rates-table-header-soc-info svg:hover {
    color: orange !important
}

.rates-table-header-cell-wrapper {
    height: 56px;
    border-bottom: 1px solid #e4e7e9;
    display: flex;
}

.rates-table-header-cell-wrapper:first-child {
    height: 70px;
}

.rates-table-header-cell-wrapper:last-child {
    height: 100px;
}

.rates-table-extra-height .rates-table-header-cell-wrapper:last-child {
    height: 117px;
}

.rates-table-header-cell-content {
    padding: 10px 0 10px 5px;
    display: flex;
    flex: auto;
    justify-content: flex-start;
    align-items: flex-end;
    line-height: 17px;
}

.rates-table-header-cell-content span {
    font-family: yandexSansTextMedium;
    font-size: 15px;
    color: #37474f;
}

.rates-table-header-cell-content svg {
    flex: 0 0 13px;
    margin-left: 5px;
    font-size: 13px;
    color: #b5c1c7;
    cursor: pointer;
    transition: .2s
}

.rates-table-header-cell-content svg:hover {
    color: #37474f;
    transition: .2s
}

.rates-table-header-cell-content.rates-table-header-cell-price span {
    text-transform: uppercase;
    color: #108ee9;
}

@media (max-width: 654px) {
    .rates-table-header {
        max-width: 100%
    }
    .rates-table-cols-wrap {
        max-width: 220px;
    }
}

@media (max-width: 370px) {
    .rates-table-cols-wrap {
        margin-right: 10px;
    }
}