@font-face {
    font-family: yandexSansDisplayBold;
    src: url('./YandexSansDisplayBold/YandexSansDisplayBold.eot');
    src: url('./YandexSansDisplayBold/YandexSansDisplayBold.woff') format('woff'), url('./YandexSansDisplayBold/YandexSansDisplayBold.ttf') format('truetype')
}

@font-face {
    font-family: yandexSansTextBold;
    src: url('./YandexSansTextBold/YandexSansTextBold.eot');
    src: url('./YandexSansTextBold/YandexSansTextBold.woff') format('woff'), url('./YandexSansTextBold/YandexSansTextBold.ttf') format('truetype')
}

@font-face {
    font-family: yandexSansTextMedium;
    src: url('./YandexSansTextMedium/YandexSansTextMedium.eot');
    src: url('./YandexSansTextMedium/YandexSansTextMedium.woff') format('woff'), url('./YandexSansTextMedium/YandexSansTextMedium.ttf') format('truetype')
}

@font-face {
    font-family: yandexSansTextRegular;
    src: url('./YandexSansTextRegular/YandexSansTextRegular.eot');
    src: url('./YandexSansTextRegular/YandexSansTextRegular.woff') format('woff'), url('./YandexSansTextRegular/YandexSansTextRegular.ttf') format('truetype')
}

@font-face {
    font-family: gothamProMedium;
    src: url('./gothamProMedium/GothamPro-Medium.eot');
    src: url('./gothamProMedium/GothamPro-Medium.woff') format('woff'), url('./gothamProMedium/GothamPro-Medium.ttf') format('truetype')
}

@font-face {
    font-family: gothamProRegular;
    src: url('./gothamProRegular/gothamPro.eot');
    src: url('./gothamProRegular/gothamPro.woff') format('woff'), url('./gothamProRegular/gothamPro.ttf') format('truetype')
}

@font-face {
    font-family: myriadProBold;
    src: url('./myriadProBold/MyriadPro-Bold.eot');
    src: url('./myriadProBold/MyriadPro-Bold.woff') format('woff'), url('./myriadProBold/MyriadPro-Bold.ttf') format('truetype')
}

@font-face {
    font-family: Montserrat;
    font-weight: 900;
    src: url('./Montserrat-ExtraBold.ttf');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    src: url('./OpenSans-Regular.ttf');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    src: url('./OpenSans-Bold.ttf');
}