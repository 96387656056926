.about3 {
    background-color: #f5f7fa;
    padding-bottom: 60px;
}

.about3 .contaier {
    padding: 0 50px;
}

.about3-body {
    display: flex;
    justify-content: center;
    width: 100%;
}

.about3-left {
    display: flex;
    flex-direction: column;
    flex: 1 3 100%;
    max-width: 450px;
    padding-top: 20px;
    padding-right: 20px;
}

.about3-left p {
    font-family: yandexSansTextRegular;
    font-size: 16px;
    line-height: 22px;
    color: #2b3d51;
    margin-bottom: 20px;
}

.about3-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 2 100%;
    padding: 0 30px;
}

.about3-slider p {
    font-family: yandexSansTextMedium;
    font-size: 13px;
    color: #2b3d51;
}

@media (max-width: 800px) {
    .about3-body {
        flex-wrap: wrap;
    }
    .about3-left {
        padding: 0;
    }
}