.modal-slider {
    top: 5% !important;
    width: auto !important;
}

.modal-slider-video {
    max-width: 145vh;
}

.modal-slider-images {
    max-width: 155vh;
}

.modal-slider .ant-modal-body {
    padding: 15px 45px;
}

.modal-slider-slide img {
    width: 100%;
}

.modal-slider-video-slide {
    position: relative;
    height: 0;
    overflow: hidden;
    width: 100%;
    padding-bottom: 56.25%;
}

.modal-slider-video-slide iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}