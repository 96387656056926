.reviews-section {
    background: #f5f7fa;
    padding-bottom: 80px;
}

.reviews-section .section-header {
    margin-bottom: 30px;
}

.reviews-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.reviews-slider {
    width: 100%;
    margin: 0 auto;
}

.reviews-section .slick-list {
    padding-top: 50px !important;
    padding-bottom: 114px !important;
    overflow: visible !important;
}

.reviews-section .slick-slide {
    padding: 0 33px;
    opacity: .28;
    transition: all linear .1s;
    outline: none;
}

.reviews-section .slick-slide.slick-active {
    opacity: 1;
}

.reviews-section .arrow-box {
    display: flex;
    justify-content: center;
    margin-top: -55px;
    position: relative;
    z-index: 100;
}

.reviews-section .arrow-box .pagingInfo {
    display: flex;
    white-space: nowrap;
    align-items: center;
    padding: 0 32px;
    font-family: 'yandexSansTextRegular', sans-serif;
    font-size: 14px;
    line-height: 55px;
    color: #22272F;
}

.reviews-section .arrow-box .pagingInfo span {
    font-family: 'yandexSansTextRegular', sans-serif;
    font-size: 12px;
    line-height: 24px;
    color: #3D8EE3;
    position: relative;
    top: -5px;
}

.review-box {
    padding: 60px 70px 64px;
    background: #FFFFFF;
    box-shadow: 0px 10px 42px rgba(181, 199, 209, 0.32);
    border-radius: 34px;
    max-width: 536px;
    position: relative;
    font-family: 'yandexSansTextRegular', sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: #4F5763;
}

.review-box .photo {
    width: 82px;
    height: 82px;
    position: absolute;
    top: 31px;
    left: -30px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0px 7px 16px rgba(158, 179, 191, 0.47);
}

.review-box .head {
    display: flex;
    flex-direction: column;
    margin: 0 0 40px;
}

.review-box .review-name {
    display: flex;
    justify-content: space-between;
}

.review-box .head .name {
    font-family: 'yandexSansTextBold', sans-serif;
    font-size: 14px;
    color: #3D8EE3;
}

.review-box .head .rating {
    display: flex;
    width: 114px;
    justify-content: space-between;
}

.review-box .head .rating img {
    display: block;
    width: 16px;
    height: 16px;
}

.review-box .review-info {
    display: flex;
    flex-direction: column;
}

.review-box .review-title {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
}

.review-box .text-review {
    height: 215px;
    padding-right: 10px;
    overflow: hidden visible;
    word-break: break-word;
}

.review-box p {
    margin: 0;
}

.review-box button {
    width: 100%;
    margin: 43px 0 0;
}

.review-box button span {
    padding: 0 0 0 35px;
    background: url("../images/ico-vk-reviews.svg") no-repeat 0 50%;
}

.reviews-section .reviews-slider-left-arrow,
.reviews-section .reviews-slider-right-arrow {
    display: flex;
}

.reviews-section .reviews-slider-left-arrow svg,
.reviews-section .reviews-slider-right-arrow svg {
    color: #3383ff;
    background: #fff;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    cursor: pointer;
    transition: .2s;
    box-shadow: 0px 0px 10px 2px rgba(10, 10, 10, .1);
}

.reviews-section .reviews-slider-left-arrow svg:hover,
.reviews-section .reviews-slider-right-arrow svg:hover {
    box-shadow: 0px 0px 20px 0px rgba(10, 10, 10, .3);
    transition: .3s;
}

.reviews-section .reviews-slider-left-arrow {
    margin-right: 25px;
}

.reviews-section .reviews-slider-right-arrow {
    margin-left: 25px;
}

.reviews-section .reviews-slider-left-arrow svg {
    padding: 10px 11px 10px 9px;
}

.reviews-section .reviews-slider-right-arrow svg {
    padding: 10px 9px 10px 11px;
}