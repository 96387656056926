footer {
    background-color: #346adf;
    width: 100%;
    color: #fff;
}

.footer-content {
    padding: 0 25px 50px 25px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-content>div {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
}

.footer-soc-links {
    align-items: flex-start;
    font-family: yandexSansTextMedium;
    font-size: 15px;
    flex: 0 0 145px;
}

.footer-soc-links-container {
    font-size: 25px;
    margin-top: 15px;
    display: flex;
    align-items: flex-end;
}

.footer-soc-links-inst-icon {
    margin-bottom: 1px;
    line-height: 1px;
}

.footer-soc-links-container svg:nth-child(n+2) {
    margin-left: 20px;
}

.footer-center {
    justify-content: flex-end;
    text-align: center;
    font-family: yandexSansTextMedium;
    font-size: 12px;
    line-height: 18px;
    color: #eeeeee;
    margin-bottom: 5px;
    padding: 0 10px;
}

.footer-feedback-wrap {
    flex: 0 0 145px;
    align-items: flex-end;
}

.footer-feedback {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.footer-feedback span {
    font-family: yandexSansTextMedium;
    font-size: 15px;
}

.footer-feedback a {
    font-size: 13px;
    font-family: yandexSansTextRegular;
    color: #eeeeee;
    margin-top: 15px;
}

.footer-feedback a:hover,
.footer-center .span-link:hover,
.footer-center a:hover {
    text-decoration: underline;
}

@media (max-width: 714px) {
    .footer-center {
        order: 3;
        flex: 100%;
    }
    .footer-feedback {
        order: 2;
    }
}

@media (max-width: 400px) {
    .footer-content {
        padding-left: 0;
        padding-right: 0;
    }
    .footer-feedback-wrap,
    .footer-soc-links {
        flex: 1 1 1px;
    }
}