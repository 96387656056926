.learn-more-cont {
    display: flex;
    justify-content: center;
    height: 90px;
    background: url('../../assets/images/about/about1-bg.png') no-repeat;
    background-position-x: center;
    background-position-y: bottom;
    margin-top: -1px;
}

.learn-more {
    position: relative;
    justify-content: center;
    font-family: gothamProRegular;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    height: 47px;
    transition: .2s
}

.learn-more:hover {
    color: #fff;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    transition: .3s
}

.learn-more:before,
.learn-more:after {
    position: absolute;
    content: '';
    transition: .2s
}

.learn-more:hover:before,
.learn-more:hover:after {
    box-shadow: 0px 0px 6px 1px rgba(255, 255, 255, 0.3);
    transition: .3s
}

.learn-more:before {
    width: 16px;
    height: 25px;
    left: calc(50% - 8px);
    border: 1px solid #fff;
    border-radius: 8px;
    bottom: 0;
}

.learn-more:after {
    bottom: 15px;
    width: 2px;
    height: 6px;
    left: calc(50% - 1px);
    background: #fff;
}