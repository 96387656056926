.App {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  flex: 1 1 1920px;
  width: 100%;
  overflow-x: hidden;
}

.App > * {
  width: 100%;
}
