nav {
    font-family: yandexSansTextMedium;
    font-size: 14px;
}

nav .regular-menu {
    display: flex;
    align-items: center;
}

nav .regular-menu ul {
    margin: 0;
}

nav .regular-menu ul li {
    display: inline-block;
    margin-right: 15px;
}

nav .regular-menu .menu-item {
    position: relative;
}

nav .regular-menu .menu-item:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #fff;
    bottom: -15px;
    left: calc(50% - 3px);
    opacity: 0;
    transition: .2s
}

nav .dropdown-menu-btn {
    font-size: 50px;
}

header .ssmm-btn {
    font-family: yandexSansTextMedium;
    padding: 12px 35px;
}

@media (hover: hover) {
    nav .regular-menu .menu-item:hover:after {
        opacity: 1;
        transition: .3s
    }
}