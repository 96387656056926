.education-section {
    padding-bottom: 80px;
}

.education-section .section-header {
    margin-bottom: 30px;
}

.education-content {
    display: flex;
    width: 100%;
}

.education-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 33%;
    max-width: 400px;
    padding: 0 15px;
    position: relative;
}

.education-col-title {
    margin-bottom: 25px;
}

.education-col-image {
    max-height: 350px;
    max-width: 100%;
}

.education-col-desc {
    font-family: yandexSansTextRegular;
    font-size: 16px;
    color: #2b3d51;
    margin-bottom: 20px;
    text-align: center;
}

.education-col .ant-btn {
    width: 180px;
}

.education-tg-link {
    color: #1890ff;
    position: absolute;
    bottom: -30px;
}

.education-tg-link:hover {
    color: #1890ff;
    opacity: .7;
}

@media (max-width: 720px) {
    .education-content {
        flex-direction: column;
        align-items: center;
    }
    .education-col {
        width: 100%;
    }
    .education-col:not(:last-child) {
        margin-bottom: 60px;
    }
}