.about1 {
    max-height: 840px;
    padding-top: 160px;
    background-image: linear-gradient(#0e3b99, #4178eb);
    background-position-x: center;
    background-position-y: bottom;
}

.about1-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 4 1 1px;
    max-width: 625px;
    padding: 20px 0 55px 0;
}

.about1-left h1 {
    color: #fff;
    margin-bottom: 25px;
}

.about1-right {
    display: flex;
    flex: 3 1 1px;
    max-width: 535px;
    justify-content: flex-end;
    align-items: center;
    background-image: url('../../assets/images/about/about1.png');
    margin-left: 6%;
    padding-bottom: 40%;
    background-repeat: no-repeat;
    background-size: contain;
}

.about1-right div {
    display: flex;
    align-items: flex-start;
    max-width: 460px;
}

.about1-right img {
    width: 100%
}

.about1-subheader {
    font-family: yandexSansTextMedium;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
    color: #b6daf5;
}

.about1-btn {
    align-self: flex-start !important;
    max-width: 305px;
    color: #2e64d8 !important;
    background: #fff !important;
    transition: .2s
}

.about1-buttons-wrap {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 580px;
}

.about1-soc-buttons-cont {
    display: flex;
    margin-left: 20px;
}

.about1-soc-button {
    display: flex;
    flex: 0 0 55px;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    border-radius: 5px;
    background: #5783e1;
    color: #fff;
}

.about1-soc-button:nth-child(n+2) {
    margin-left: 10px;
}

.about1-soc-buttons-cont .fa-vk {
    font-size: 30px;
}

.about1-soc-buttons-cont .fa-instagram {
    font-size: 30px;
}

.about1-soc-buttons-cont .fa-facebook-square {
    font-size: 30px;
}

.about1-soc-buttons-cont .fa-odnoklassniki {
    font-size: 30px;
}

@media (max-width: 900px) {
    .about1 {
        padding-top: 106px;
    }
    .about1-btn {
        align-self: center !important;
    }
    .about1-left {
        max-width: 100%;
        padding: 0 0 50px 0;
        text-align: center;
        align-items: center;
    }
    .about1-right {
        display: none;
    }
}

@media (max-width: 640px) {
    .about1-buttons-wrap {
        flex-wrap: wrap;
        justify-content: center;
    }
    .about1-soc-buttons-cont {
        flex: 0 0 100%;
        justify-content: center;
        margin-top: 20px;
        margin-left: 0;
    }
}

@media (hover: hover) {
    .about1-btn:hover {
        box-shadow: 0px 0px 30px 5px rgba(26, 26, 26, 0.3);
        transition: .3s
    }
}

/*
@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    .about1-right {
        background-image: url('../../assets/images/about/about1-2x.png');
    }
}*/