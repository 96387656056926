@import './assets/fonts/fonts.css';
@import '~antd/dist/antd.css';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
* {
  margin: 0;
  padding: 0;
  min-height: 0;
  min-width: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

#root {
  display: flex;
  justify-content: center;
}

a,
h1,
p {
  white-space: pre-wrap;
}

p {
  margin-bottom: 1em;
}

a,
a:hover,
a:active {
  color: #fff;
}

a:active,
a:hover,
a:focus {
  text-decoration: none;
}

section,
nav {
  display: flex;
  box-sizing: border-box;
}

.container {
  display: flex;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.section-header {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px 0 15px 0;
  text-align: center;
}

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

h1 {
  font-family: yandexSansDisplayBold;
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 10px;
  color: #2f3b64;
}

h2 {
  font-family: yandexSansTextBold;
  font-size: 25px;
  margin-bottom: 10px;
  color: #2f3b64;
}

h3 {
  font-family: yandexSansTextBold;
  font-size: 20px;
  margin-bottom: 10px;
  color: #2f3b64;
  text-align: center;
}

h4 {
  font-family: yandexSansTextRegular;
  font-size: 20px;
  margin: 0;
  color: #37474f;
}

h5 {
  color: #5B656F;
  font-family: yandexSansTextMedium;
  font-size: 22px;
  line-height: 20px;
}

.list-item {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  font-family: yandexSansTextRegular;
  font-size: 13px;
  color: #2b3d51;
}

.list-item-more {
  color: #7e8a95;
}

.list-item-more svg {
  color: #0e5fa4 !important;
  background-color: #e9f2fa !important;
}

.list-item>svg:first-child {
  color: #0ea461;
  width: 18px !important;
  min-width: 18px;
  height: 18px;
  background-color: #e9faf0;
  border-radius: 9px;
  padding: 5px;
  margin-right: 7px;
}

.subheader {
  font-family: yandexSansTextBold;
  font-size: 15px;
  line-height: 24px;
  margin: 12px 0;
  color: #7e8a95;
  text-transform: uppercase;
}

.ssmm-btn {
  font-family: gothamProMedium;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  border-radius: 23px;
  padding: 12px 0;
  color: #fff;
  outline: none;
  cursor: pointer;
}

.ssmm-btn[disabled] {
  color: #fff;
  background-color: gray;
}

.ssmm-btn-green {
  background-color: rgba(45, 202, 114, 1);
}

.ssmm-btn-green:hover {
  background-color: rgba(45, 202, 114, .85);
}

.ssmm-btn-blue {
  background-color: rgba(17, 142, 234, 1)
}

.ssmm-btn-blue:hover {
  background-color: rgba(17, 142, 234, .85)
}

.ssmm-btn-white {
  border: 3px solid #3D8EE3;
  color: #4F5763;
  background: #fff;
  transition: all linear .1s;
}

.ssmm-btn-white:hover {
  background: #EBF4F9;
}

.ssmm-btn-white:active {
  background: #C3E0F0;
}

.ssmm-btn-large {
  font-family: myriadProBold;
  font-size: 18px;
  padding: 14px 30px;
  border-radius: 28px;
  width: 100%;
}

.span-link {
  cursor: pointer;
}

.modules-desc {
  width: 100%;
  display: flex;
}

.modules-desc-links>span {
  font-family: yandexSansTextMedium;
  color: #7e8a95;
  font-size: 16px;
  line-height: 25px;
}

.modules-desc-link {
  color: #37474f;
  border-bottom: dashed 1px;
  padding-bottom: 2px;
  cursor: pointer;
  transform: .2s
}

.modules-desc-link:hover {
  color: #108ee9;
  transform: .2s
}

.shadow {
  box-shadow: 0px 8px 40px 0px rgba(26, 26, 26, 0.1);
}

.ssmm-video-wrap {
  position: relative;
  height: 0;
  overflow: hidden;
  width: 100%;
  padding-bottom: 56.25%;
}

.ssmm-video-wrap>img {
  width: 100%;
}

.ssmm-video-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ssmm-large-checkbox .ant-checkbox-inner {
  width: 30px;
  height: 30px;
}

.ssmm-large-checkbox .ant-checkbox-inner::after {
  top: 39%;
  left: 18%;
  width: 12px;
  height: 22px;
}

.ssmm-large-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-width: 3px;
}

@media (max-width: 900px) {
  .container {
    padding: 0 20px;
  }
}

@media (hover: hover) {
  a:hover {
    color: #fff;
  }
}