.rates-table-col {
    display: flex;
    flex-direction: column;
    flex: auto;
    min-width: 160px;
    max-width: 220px;
}

.rates-table-col-header {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 9.1%;
    margin-bottom: 10px;
}

.rates-table-col-header h4 {
    color: #fff;
    font-family: yandexSansTextMedium;
}

.rates-table-col-header span {
    font-size: 17px;
    font-family: yandexSansTextRegular;
}

.rates-table-col-green .rates-table-col-header {
    background-color: #6fd975;
}

.rates-table-col-orange .rates-table-col-header {
    background-color: #f3b956;
}

.rates-table-col-purple .rates-table-col-header {
    background-color: #8e21fe;
}

.rates-table-col-black .rates-table-col-header {
    background-color: #000;
}

.rates-table-col-green .rates-table-col-header span {
    color: #dcf9f9;
}

.rates-table-col-orange .rates-table-col-header span {
    color: #fff9f0;
}

.rates-table-col-purple .rates-table-col-header span {
    color: #dcbaff;
}

.rates-table-col-black .rates-table-col-header span {
    color: #fff;
}

.rates-table-col-body {
    display: flex;
    flex-direction: column;
}

.rates-table-col-cell-wrapper {
    height: 56px;
    border-bottom: 1px solid #e4e7e9;
    padding-left: 9.1%;
    display: flex;
}

.rates-table-col-cell-wrapper:first-child {
    height: 70px;
}

.rates-table-col-cell-price {
    height: 100px;
}

.rates-table-extra-height .rates-table-col-cell-price {
    height: 117px;
}

.rates-table-col-cell-activate-btn {
    height: 86px;
    justify-content: center;
    align-items: center;
    border: none;
}

.rates-table-col-cell-activate-btn .ssmm-btn {
    width: 100%;
}

.rates-table-col-cell-content {
    background-color: #f7f7f7;
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: flex-end;
    padding: 10px;
    font-family: yandexSansTextBold;
    font-size: 18px;
    color: #37474f;
    line-height: 17px;
}

.privileges-gray {
    font-family: yandexSansTextRegular;
    font-size: 16px;
    color: #78909c;
}

.rates-table-price-cont {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
}

.rates-table-full-price {
    color: #f17775;
    text-decoration: line-through;
}

.rates-table-price-monthly {
    font-family: yandexSansTextMedium;
    font-size: 25px;
    color: #8e67b6;
    line-height: 38px;
    white-space: nowrap;
}

.rates-table-price {
    font-family: yandexSansTextRegular;
    font-size: 15px;
    color: #b0bec5;
    line-height: 18px;
    white-space: nowrap;
}

.rates-table-col-soc-icons {
    display: flex;
    align-items: center
}

.rates-table-col-soc-icons .fa-vk {
    font-size: 22px;
}

.rates-table-col-soc-icons .fa-facebook {
    font-size: 19px;
}

.rates-table-col-soc-icons .fa-odnoklassniki-square {
    font-size: 20px;
}

.rates-table-col-soc-icons .fa-instagram {
    font-size: 20px;
}

.rates-table-col-soc-icons svg:nth-child(n+2) {
    margin-left: 8px;
}

.rates-table-col-soc-warning {
    cursor: pointer;
    margin-left: 5px;
    color: orange;
}

.rates-table-col-soc-under-development {
    opacity: .3;
}

.rates-table-specialOffer {
    color: rgba(45, 202, 114, 1);
    font-size: 15px;
    text-align: center;
    line-height: 20px;
}

.rates-table-specialOffer-price {
    font-size: 20px;
    display: block;
}

.rates-table-col-special-warning-icon {
    cursor: pointer;
    margin-left: 5px;
    font-size: 18px;
}

.ant-popover.rates-table-popover {
    font-family: yandexSansTextRegular;
    font-size: 14px;
    font-weight: 400;
    min-width: 300px;
    max-width: 400px;
}