.target-slider {
    width: 100%;
}

.target-slider-body {
    display: flex;
}

.target-slider-list {
    text-align: center;
    line-height: 30px;
    padding: 0 10%;
}

.target-slider-list button {
    font-family: yandexSansTextBold;
    font-size: 15px;
    line-height: 14px;
    color: #7e8a95;
    white-space: nowrap;
    display: inline-flex;
    transition: .2s
}

.target-slider-list button:hover {
    color: #2f3b64;
    transition: .3s
}

.target-slider-list .active-slide {
    color: #2f3b64;
}

.target-slider-list button:nth-child(n+2),
.target-slider-list a:nth-child(n+2) {
    margin-left: 7px;
    padding-left: 7px;
    border-left: 2px solid #7e8a95;
}

.target-slider-list .notinthelist {
    color: rgba(17, 142, 234, .7);
    margin-right: 7px;
    padding-right: 7px;
    border-right: 2px solid #7e8a95;
}

.target-slider-list .notinthelist:hover {
    color: rgba(17, 142, 234, 1);
}

.target-slider-list .notinthelist.active-slide {
    color: rgba(17, 142, 234, 1);
}

.target .target-slider-left-arrow,
.target .target-slider-right-arrow {
    position: relative;
    display: flex;
    flex: 1 0 50px;
    justify-content: center;
    align-items: center;
    max-width: 80px;
}

.target .target-slider-left-arrow svg,
.target .target-slider-right-arrow svg {
    color: #3383ff;
    background: #e7e7e7;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    transition: .2s;
}

.target .target-slider-left-arrow svg:hover,
.target .target-slider-right-arrow svg:hover {
    box-shadow: 0px 0px 30px 0px rgba(10, 10, 10, .5);
    transition: .3s;
}

.target .target-slider-right-arrow:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 1px;
    left: 0;
    background: linear-gradient( to bottom, #fff, #000, #fff);
    opacity: .3;
}

.target .target-slider-left-arrow:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 1px;
    right: 0;
    background: linear-gradient( to bottom, #fff, #000, #fff);
    opacity: .3;
}

.target .target-slider-left-arrow svg {
    padding: 10px 11px 10px 9px;
}

.target .target-slider-right-arrow svg {
    padding: 10px 9px 10px 11px;
}

.target .slick-list {
    padding: 50px !important;
    border-radius: 20px;
}

.target-slider-wrap {
    display: flex;
    flex: 10 1 500px;
}

.target-slider-spin-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
}

/* 100% высота слайдов */

.slick-track {
    display: flex !important;
}

.slick-slide {
    height: inherit !important;
}

.target-slider-wrap .slick-slide>div,
.target-slide-wrap,
.target-slide {
    height: 100%;
}

@media (max-width: 500px) {
    .target .target-slider-left-arrow,
    .target .target-slider-right-arrow {
        display: none;
    }
}

@media (hover: hover) {
    .target-slider-list a:hover {
        color: #2f3b64;
        transition: .2
    }
}