.rates-table-cols-prevBtn,
.rates-table-cols-nextBtn {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #fbfbfb;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, .4);
    opacity: .4;
}

.rates-table-cols-prevBtn.rates-table-cols-prevBtn-active,
.rates-table-cols-nextBtn.rates-table-cols-nextBtn-active {
    opacity: 1;
    cursor: pointer;
}

.rates-table-cols-prevBtn div,
.rates-table-cols-nextBtn div {
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
}

.rates-table-cols-prevBtn {
    top: 60px;
    left: 0;
}

.rates-table-cols-prevBtn div {
    width: 20px;
    height: 20px;
    border-bottom: 3px solid #b5c1c7;
    border-left: 3px solid #b5c1c7;
    margin-left: 6px;
}

.rates-table-cols-nextBtn {
    top: 60px;
    right: -20px;
}

.rates-table-cols-nextBtn div {
    width: 20px;
    height: 20px;
    border-top: 3px solid #b5c1c7;
    border-right: 3px solid #b5c1c7;
    margin-right: 6px;
}